import React from "react";
import { Link } from "react-router-dom";

const CallToActiion = ({ t, i18n }) => {
  return (
    <div className="bg-white   overflow-hidden relative px-8 grid grid-cols-5">
      <div className="w-full col-span-5 xl:col-span-2 flex justify-center items-center my-4 sm:my-4 sm:hidden">
        <img src={"img/home/3d.webp"} className="w-full rounded-xl " />
      </div>
      <div className="text-start  py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20 col-span-5 xl:col-span-3">
        <h2 className="text-lg  font-extrabold text-black   sm:text-4xl">
          <span className="block mb-2 text-orange-500">
            Welcome to Alpha Appliances
          </span>
          <span className="block text-indigo-500 text-3xl sm:text-5xl">
            Your Ultimate Kitchen Solution!
          </span>
        </h2>
        <p className="text-xl text-justify mt-4 text-gray-400">
          A host of Kitchen Essentials such as Hoods/Chimney. Built-in Hobs,
          Hob-Cooktop Hybrids, Cooktops, Built-in ovens, Cooking Ranges,
          Dishwashers, Induction Cooktops, Water Purifiers, Water Geysers and
          other small appliances are brought to you by the group. And{" "}
          <span className="text-orange-500">Alpha Appliances</span> is on its
          way to reinvent the luxury kitchen space in the Country of India.
        </p>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className="mt-12 inline-flex rounded-md shadow">
            <Link
              to={"contact-us"}
              state={{ message: `Inquiry for Ultimate Kitchen Solutions` }}
              type="button"
              className="py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              {"Inquiry Now"}
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full col-span-5 xl:col-span-2  justify-center items-center my-4 sm:my-4 hidden sm:flex">
        <img src={"img/home/3d.webp"} className="w-full rounded-xl " />
      </div>
    </div>
  );
};

export default CallToActiion;
