import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ImageModal from "../components/common/ImageModal";

const Products = () => {
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const filters = {
    CHIMNEY: "chimney",
    GAS: "gas",
  };

  const products = [
    {
      img: "img/products/001.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/002.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/003.webp",
      category: filters.GAS,
    },
    {
      img: "img/products/004.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/005.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/006.webp",
      category: filters.GAS,
    },
    {
      img: "img/products/007.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/008.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/009.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/010.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/011.webp",
      category: filters.CHIMNEY,
    },
    {
      img: "img/products/012.webp",
      category: filters.CHIMNEY,
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <div className="px-6 md:px-12 relative">
      <h1 className="text-4xl font-bold text-indigo-600 text-center my-4 ">
        Products
      </h1>

      <ul className={"w-full md:w-[40%] m-auto grid grid-cols-3  my-8 mt-16"}>
        <li className="nav-item flex justify-center items-center">
          <button
            className={
              "nav-link p-2 px-4 rounded-md  " +
              (filterKey === "*" ? "bg-indigo-600 text-white" : "bg-indigo-300")
            }
            onClick={handleFilterKeyChange("*")}
          >
            All
          </button>
        </li>

        <li className="nav-item flex justify-center items-center">
          <button
            className={
              "nav-link p-2 px-4 rounded-md  " +
              (filterKey === filters.CHIMNEY
                ? "bg-indigo-600 text-white"
                : "bg-indigo-300")
            }
            onClick={handleFilterKeyChange(filters.CHIMNEY)}
          >
            Chimney
          </button>
        </li>

        <li className="nav-item flex justify-center items-center">
          <button
            className={
              "nav-link p-2 px-4 rounded-md  " +
              (filterKey === filters.GAS
                ? "bg-indigo-600 text-white"
                : "bg-indigo-300")
            }
            onClick={handleFilterKeyChange(filters.GAS)}
          >
            Gas Stove
          </button>
        </li>
      </ul>
      <div className=" portfolio-filter filter-container gap-2">
        {products.length > 0 &&
          products.map((product, index) => (
            <div
              className={
                "filter-item p-4 w-full sm:w-1/2 md:w-1/3 " + product.category
              }
              key={index}
            >
              <img
                className=" rounded-xl shadow-xl border-indigo-600  cursor-pointer"
                src={product.img}
                alt=""
                onLoad={() => {
                  setimagesLoaded(imagesLoaded + 1);
                }}
                onClick={() => {
                  setImgSrc(product.img);
                  setIsModalOpen(true);
                }}
              />
            </div>
          ))}
      </div>
      <div className="w-full">
        <img className="w-full my-4" src={"img/products/catlog.png"} alt="" />
      </div>
      <div className="w-full flex justify-center items-center my-4">
        <a
          className="px-4 py-2 bg-indigo-600 text-white rounded-md"
          href="docs/catlog.pdf"
        >
          Download Catlog
        </a>
      </div>
      {isModalOpen && (
        <ImageModal src={imgSrc} setIsModalOpen={setIsModalOpen}></ImageModal>
      )}
    </div>
  );
};

export default Products;
