import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="p-4 bg-slate-100 rounded-lg shadow md:flex md:items-center md:justify-between md:p-6  ">
      <span className="text-sm text-gray-500 hidden  sm:inline text-center  ">
        © 2022{" "}
        <Link to={"/"} className="hover:underline" target="_blank">
          Alpha Appliences
        </Link>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 sm:mt-0 justify-center">
        <li>
          <Link
            to={"/about-us"}
            className="mr-4 text-sm text-gray-500 hover:underline md:mr-6  "
          >
            About
          </Link>
        </li>

        <li>
          <Link
            to={"/products"}
            className="mr-4 text-sm text-gray-500 hover:underline md:mr-6  "
          >
            Products
          </Link>
        </li>
        <li>
          <Link
            to={"/contact-us"}
            className="text-sm text-gray-500 hover:underline  "
          >
            Contact
          </Link>
        </li>
      </ul>

      <div className="text-sm text-gray-500 text-center mt-4 sm:mt-0">
        Designed and Developed by{" "}
        <a className="underline" href="https://axire.in/">
          AXIRE
        </a>
      </div>
      <span className="text-sm text-gray-500 block mt-1  sm:hidden text-center  ">
        © 2022{" "}
        <Link to={"/"} className="hover:underline">
          Alpha Appliences
        </Link>
        . All Rights Reserved.
      </span>
    </footer>
  );
};

export default Footer;
