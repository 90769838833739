import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  return (
    <div>
      <nav className="bg-slate-50   shadow header">
        <div className="px-8 mx-auto max-w-7xl">
          <div className="flex items-center justify-between h-20">
            <div className="w-full justify-between flex items-center">
              <a className="flex-shrink-0" href="/">
                <img className=" h-[70px]" src="logo.png" alt="Workflow" />
              </a>
              <div className="hidden md:block">
                <div className="flex items-center ml-10 space-x-4">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-800  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                        : "text-gray-400  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                    }
                    to={"/"}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-800  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                        : "text-gray-400  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                    }
                    to={"about-us"}
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-800  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                        : "text-gray-400  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                    }
                    to={"/products"}
                  >
                    Products
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "text-gray-800  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                        : "text-gray-400  hover:text-gray-800   px-3 py-2 rounded-md text-base font-semibold"
                    }
                    to={"/contact-us"}
                  >
                    Contact
                  </NavLink>
                  <div className="flex items-center ml-10 space-x-4">
                    <a
                      className="  text-white ml-10"
                      href="mailto:info@alphaappliances.in"
                    >
                      <img className="w-8" src={"img/mail.png"} alt="" />
                    </a>
                    <a className="  text-white" href="tel:+919558886009">
                      <img className="w-8" src={"img/call.png"} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="flex items-center ml-4 md:ml-6"></div>
            </div>
            <div className="flex -mr-2 md:hidden">
              <button
                className="text-gray-800   hover:text-gray-400 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                onClick={() => setIsHeaderOpen(!isHeaderOpen)}
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="w-8 h-8"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isHeaderOpen && (
          <div className="md:hidden absolute bg-slate-50 w-full text-center z-50">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                    : "text-gray-400 hover:text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                }
                to={"/"}
                onClick={() => {
                  setIsHeaderOpen(false);
                }}
              >
                Home
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                    : "text-gray-400 hover:text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                }
                to={"about-us"}
                onClick={() => {
                  setIsHeaderOpen(false);
                }}
              >
                About Us
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                    : "text-gray-400 hover:text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                }
                to={"/products"}
                onClick={() => {
                  setIsHeaderOpen(false);
                }}
              >
                Products
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                    : "text-gray-400 hover:text-gray-800   block px-3 py-2 rounded-md text-base font-semibold"
                }
                to={"/contact-us"}
                onClick={() => {
                  setIsHeaderOpen(false);
                }}
              >
                Contact
              </NavLink>
              <div className="flex items-center justify-center  space-x-4 ">
                <a
                  className="  text-white m-1 "
                  href="mailto:info@alphaappliances.in"
                >
                  <img className="w-8 " src={"img/mail.png"} alt="" />
                </a>
                <a className="  text-white m-1" href="tel:+919558886009">
                  <img className="w-8" src={"img/call.png"} alt="" />
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
