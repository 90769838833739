import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Services = () => {
  const services = [
    {
      name: "Electric Chimney Repair",
      img: "img/home/services/chimney_repair.webp",
    },
    {
      name: "Electric Chimney Installation",
      img: "img/home/services/chimney_installation.webp",
    },
    {
      name: "Gas Stove Repair & Services",
      img: "img/home/services/gas_repair.webp",
    },
    {
      name: "HOB Repair & Services",
      img: "img/home/services/HOB_repair.webp",
    },
  ];

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-gradient-to-tr from-red-300 to-yellow-200 pt-10">
      <h2 className="text-4xl font-bold text-indigo-600 text-center">
        SERVICES
      </h2>

      <div className="py-20">
        <div className="m-10">
          <Slider {...settings}>
            {services.length > 0 &&
              services.map((service, index) => (
                <div className="p-4">
                  <div
                    className=" bg-white px-6 pt-6 pb-2 rounded-xl shadow-lg"
                    key={index}
                  >
                    <div className="relative">
                      <img
                        className="w-full rounded-xl"
                        src={service.img}
                        alt={service.name}
                      />
                    </div>
                    <h2 className="mt-4 text-gray-800 text-2xl font-bold ">
                      {service.name}
                    </h2>
                    <div className="my-4 flex justify-center items-center">
                      <Link
                        to={"contact-us"}
                        state={{ message: `Inquiry for ${service.name}` }}
                        className="mt-4 text-xl w-full text-white bg-indigo-600 text-center rounded-xl shadow-lg px-4 py-2"
                      >
                        Inquiry Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Services;
