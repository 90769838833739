import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const testimonials = [
    {
      desc: "I couldn't be happier with my new electric chimney from Alpha Appliances. It's made a huge difference in my kitchen, and the staff were incredibly helpful and friendly throughout the selection and installation process.",
      name: "Ashokbhai Rachhadiya",
      city: "Ahmedabad",
    },
    {
      desc: "I highly recommend Alpha Appliances for anyone in need of a new gas stove. Their prices were competitive, their service was exceptional, and the installation was quick and professional.",
      name: "Pooja Maheta",
      city: "Gandhinagar",
    },
    {
      desc: "Alpha Appliances exceeded my expectations with their wide range of hobs and personalized recommendations. The installation process was seamless, and I'm thrilled with the performance of my new hob.",
      name: "Reshmaben Patel",
      city: "Ahmedabad",
    },
  ];

  return (
    <div className="bg-white   w-full mx-auto p-8">
      <Slider {...settings}>
        {testimonials.length > 0 &&
          testimonials.map((value, index) => (
            <div key={index}>
              <p className="text-gray-600   w-full md:w-2/3 m-auto text-center text-lg md:text-3xl">
                <span className="font-bold text-indigo-500">“ </span>
                {value.desc}
                <span className="font-bold text-indigo-500"> ”</span>
              </p>
              <div className="flex items-center justify-center mt-8">
                <div className="flex items-center justify-center ml-2">
                  <span className="mr-2 text-lg font-semibold text-indigo-500">
                    {value.name}
                  </span>
                  <span className="text-xl font-light text-gray-400">/</span>
                  <span className="ml-2 text-gray-400 text-md">
                    {value.city}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </Slider>
      <div className="bg-white   ">
        <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 className="text-3xl font-extrabold text-black   sm:text-4xl text-center sm:text-left">
            <span className="block text-2xl sm:text-3xl">
              Ready to Upgrade Your Kitchen?
            </span>
            <span className="block text-indigo-500 text-3xl sm:text-4xl my-4 sm:my-0">
              Discover Alpha Appliances Now!
            </span>
          </h2>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="flex  sm:inline-flex rounded-md shadow">
              <Link
                to={"/contact-us"}
                type="button"
                className="py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
