import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";

const MainLayout = ({ children }) => {
  return (
    <div className="">
      <Header></Header>
      {children}
      <Footer></Footer>
    </div>
  );
};

export default MainLayout;
