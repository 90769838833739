import React from "react";

const ImageModal = ({ src, setIsModalOpen }) => {
  return (
    <div
      className="w-[100vw] h-[100vh] fixed top-0 left-0 bg-black/60 flex justify-center items-center"
      onClick={() => {
        setIsModalOpen(false);
      }}
    >
      <img
        className="max-w-[85%] max-h-[85%]"
        src={src}
        alt=""
        onClick={(e) => e.stopPropagation()}
      />
      <img
        className="absolute top-4 right-8 w-8 cursor-pointer"
        src={"img/close.svg"}
        alt=""
        onClick={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default ImageModal;
