import React from "react";
import Testimonials from "../components/common/Testimonials";

const AboutUs = () => {
  const technologies = [
    {
      name: "Motion Sensor",
      desc: "Enabled with motion sensor technology, our cutting-edge chimneys can detect any movement in front of them. This is a unique feature that gives our chimneys a futuristic edge. The motion sensor feature enables the chimneys to be switched on with just the wave of a hand. This makes the chimneys extremely easy-to-use and provides a handy alternative to the chimney's monitoring which even children can control.",
      img: "img/hand_sensor.png",
    },
    {
      name: "Maximum Suction",
      desc: "The chimneys come with powerful suction power. The maximum suction feature makes our chimneys one of the best kitchen chimneys available in India. The maximum suction leads to removal of combustion products, fumes, odour, steam, heat, smoke, and grease that develops during the process of cooking. The flow of air is most important as it prevents your kitchen from turning into a smoke-zone.",
      img: "img/maximum_suction.png",
    },
    {
      name: "Energy Efficient Light",
      desc: "Chimneys from the house of Alpha Appliances are highly energy-efficient. The lamps illuminate a wider surface but consume less power; this makes them handy for viewing your countertops easily. This unique feature makes our chimneys eco-friendly as well as cost-effective.",
      img: "img/energy.jpg",
    },
    {
      name: "Baffle Filter",
      desc: "Made from stainless steel, our chimneys come with baffle filters which effectively push the oil and residue to collect inside the baffle. The grease-saturated air is forced to change directions repeatedly. As the grease droplets are slower than air, hence they get condensed inside the metal blades and end up getting collected in the filter tray.",
      img: "img/buffle_filter.jpg",
    },
    {
      name: "Metal Blower",
      desc: "Metal blowers ensure maximum suction and durability. The metal blowers are connected to a duct system. The metal blower eliminates the task of replacement of filters at regular intervals. Thus they elongate the life term of the chimney. They also help to remove the excess heat in the kitchen area. The metal blowers help the chimneys to exhaust all kinds of air-borne contamination.",
      img: "img/mettle_blower.jpg",
    },
    {
      name: "Timer",
      desc: "Most of us lead a busy life comprising hectic schedules, often with the need to engage in multitasking. This leaves us with very little time for ourselves. On certain occasions, we have of things on our mind and maintenance of kitchen appliances is likely to be among the last on the list.",
      img: "img/digital_timer.jpg",
    },
    {
      name: "Auto Clean",
      desc: "Auto Clean is a state of the art feature that helps in cleaning the oil and residue formed inside the motor with just one touch. Chimneys that are equipped with the auto clean feature are extremely useful because cleaning chimneys can be time-consuming. With the auto clean feature, the residue and oil collected in the chimney get cleaned on its own. This makes your work easier and the appliance low maintenance.",
      img: "img/digital_cleaning.png",
    },
    {
      name: "Powerful Motor",
      desc: "Alpha Appliances presents Chimneys enabled with Powerful motor with high suction capacity, longer operational life, less power consumption, and less noise. What more could one ask for? The high suction capacity ensures that the smoke is sucked out quickly. Being energy-efficient, this chimney is a must-have in your kitchen. The motor is not just power-packed but is also robust in built and is highly durable.",
      img: "img/powerfull_moter.jpg",
    },
  ];
  return (
    <>
      <div className="py-16 bg-white">
        <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:5/12 lg:w-5/12">
              <img
                className="rounded-md my-2"
                src={"img/about/lyra.webp"}
                alt="image"
                loading="lazy"
                width
                height
              />
              <img
                className="rounded-md my-2"
                src={"img/about/about.webp"}
                alt="image"
                loading="lazy"
                width
                height
              />
            </div>
            <div className="md:7/12 lg:w-6/12">
              <h2 className="text-2xl font-bold text-indigo-600 md:text-2xl">
                Welcome to{" "}
                <span className="text-orange-500">Alpha Appliances,</span> your
                Single Window solutions like sales, services, amc and sparres
                for your kitchen appliances.
              </h2>
              <p className="mt-6 text-gray-600">
                <span className="text-orange-500">Alpha</span> welcomes you to
                the world of enhanced and delightful cooking. Our exclusive
                range of exquisitely crafted kitchen appliances are sleek,
                stylish, durable and high on perfomance.{" "}
                <span className="text-orange-500">Alpha's</span> wide range of
                Hobs & Hoods are inspired by aesthetically pleasing{" "}
                <strong>"Indian designs"</strong> and{" "}
                <strong> "Indian craftmanship"</strong>.
              </p>
              <p className="mt-4 text-gray-600">
                {" "}
                For a smoke-free and healthy cooking experience, you can bank
                upon the chimneys from
                <span className="text-orange-500"> Alpha Appliances</span>. A
                unique blend of futuristic design and cutting-edge technology,
                these chemneys let you cook minus the fumes. To complement your
                classy kitchen interiors, Alpha's Chimneys are crafted using
                superios materials and are designed keeping modern contemporary
                aesthtics in mind. For best in class ventilation and robust
                build, Chimneys from
                <span className="text-orange-500"> Alpha's Appliances</span> are
                your best bet. Based on your kitchen space, you can choose from
                Island Hoods, Designer Hoods,, Decorative Hoods, Auto clean
                Hoods, & Straight Line Hoods.
              </p>
              <p className="mt-4 text-gray-600">
                {" "}
                All the products are ergonomically designed with highest degree
                of care taken to choose material, ensure safety and save
                electric consumption. The products are easy-to-use, easy to
                install and are manufactured using cutting-edge technology.
                Moreover, <span className="text-orange-500">Alpha</span> Kitchen
                Hoods are specially designed to bring silence to the kitchen and
                create a pleasant cooking experience.
              </p>
              {/* <p className="mt-4 text-gray-600">
                {" "}
                At Alpha Appliances, we believe that shopping for kitchen
                appliances should be a stress-free and enjoyable experience.
                That's why we offer a friendly and knowledgeable staff who is
                always available to answer any questions you may have and to
                help you find the perfect appliance for your needs.
              </p>
              <p className="mt-4 text-gray-600">
                {" "}
                Don't wait any longer, visit us today and see why Alpha
                Appliances is the best choice for all your kitchen appliance
                needs. Our commitment to quality, reliability, and customer
                satisfaction makes us the ultimate solution for your kitchen.
                Upgrade your kitchen, upgrade your life with Alpha Appliances!
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="px-2 sm:mx-10 md:mx-16">
        <h2 className="text-4xl font-bold text-gray-800 md:text-2xl text-center">
          Technologies
        </h2>
        <div className="p-2">
          {technologies.length > 0 &&
            technologies.map((value, index) => (
              <div
                className="grid grid-cols-5 m-2 my-2 mx-2 bg-white shadow-xl p-4 rounded-lg"
                key={index}
              >
                <div
                  className={
                    "col-span-5  justify-center items-center w-full sm:col-span-1 " +
                    (index % 2 === 0 ? "flex sm:hidden" : "flex")
                  }
                >
                  <img
                    className="max-w-28 max-h-28 p-4 rounded-md m-auto"
                    src={value.img}
                    alt=""
                  />
                </div>
                <div className={"col-span-5 sm:col-span-4 "}>
                  <h2 className="font-bold">{value.name}</h2>
                  <p>{value.desc}</p>
                </div>
                <div
                  className={
                    "col-span-5  justify-center items-center w-full sm:col-span-1 " +
                    (index % 2 !== 0 ? "hidden sm:hidden" : "hidden sm:flex")
                  }
                >
                  <img
                    className="max-w-28 max-h-28 p-4 rounded-md m-auto"
                    src={value.img}
                    alt=""
                  />
                </div>
              </div>
            ))}
          {/* <div className="grid grid-cols-10">
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/hand_sensor.png"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Motion Sensor</strong>
                <br />
                Enabled with motion sensor technology, our cutting-edge chimneys
                can detect any movement in front of them. This is a unique
                feature that gives our chimneys a futuristic edge. The motion
                sensor feature enables the chimneys to be switched on with just
                the wave of a hand. This makes the chimneys extremely
                easy-to-use and provides a handy alternative to the chimney's
                monitoring which even children can control.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/maximum_suction.png"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Maximum Suction :</strong> <br />
                The chimneys come with powerful suction power. The maximum
                suction feature makes our chimneys one of the best kitchen
                chimneys available in India. The maximum suction leads to
                removal of combustion products, fumes, odour, steam, heat,
                smoke, and grease that develops during the process of cooking.
                The flow of air is most important as it prevents your kitchen
                from turning into a smoke-zone.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/energy.jpg"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Energy Efficient Light :</strong> <br />
                Chimneys from the house of Alpha Appliances are highly
                energy-efficient. The lamps illuminate a wider surface but
                consume less power; this makes them handy for viewing your
                countertops easily. This unique feature makes our chimneys
                eco-friendly as well as cost-effective.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/buffle_filter.jpg"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Baffle Filter :</strong> <br />
                Made from stainless steel, our chimneys come with baffle filters
                which effectively push the oil and residue to collect inside the
                baffle. The grease-saturated air is forced to change directions
                repeatedly. As the grease droplets are slower than air, hence
                they get condensed inside the metal blades and end up getting
                collected in the filter tray.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/mettle_blower.jpg"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Metal Blower</strong> <br />
                Metal blowers ensure maximum suction and durability. The metal
                blowers are connected to a duct system. The metal blower
                eliminates the task of replacement of filters at regular
                intervals. Thus they elongate the life term of the chimney. They
                also help to remove the excess heat in the kitchen area. The
                metal blowers help the chimneys to exhaust all kinds of
                air-borne contamination.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/digital_timer.jpg"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Timer :</strong> <br />
                Most of us lead a busy life comprising hectic schedules, often
                with the need to engage in multitasking. This leaves us with
                very little time for ourselves. On certain occasions, we have of
                things on our mind and maintenance of kitchen appliances is
                likely to be among the last on the list.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/digital_cleaning.png"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Auto Clean :</strong> <br />
                Auto Clean is a state of the art feature that helps in cleaning
                the oil and residue formed inside the motor with just one touch.
                Chimneys that are equipped with the auto clean feature are
                extremely useful because cleaning chimneys can be
                time-consuming. With the auto clean feature, the residue and oil
                collected in the chimney get cleaned on its own. This makes your
                work easier and the appliance low maintenance.
              </div>
            </div>
            <div className="col-span-1 flex justify-center items-center">
              <img
                className="w-28 h-28 p-4 rounded-md"
                src="img/powerfull_moter.jpg"
                alt=""
              />
            </div>
            <div className="col-span-9 flex justify-center items-center">
              <div>
                <strong>Powerful Motor :</strong> <br />
                Alpha Appliances presents Chimneys enabled with Powerful motor
                with high suction capacity, longer operational life, less power
                consumption, and less noise. What more could one ask for? The
                high suction capacity ensures that the smoke is sucked out
                quickly. Being energy-efficient, this chimney is a must-have in
                your kitchen. The motor is not just power-packed but is also
                robust in built and is highly durable.
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Testimonials></Testimonials>{" "}
    </>
  );
};

export default AboutUs;
