import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const form = useRef();

  const location = useLocation();
  const message = location?.state?.message;

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    contact: "",
    message: message ?? "",
  });
  const [errorStatus, setErrorStatus] = useState({
    name: false,
    email: false,
    contact: false,
    message: false,
  });
  const [sendStatus, setSendStatus] = useState(false);

  const sendEmail = (e) => {
    setSendStatus(true);
    e.preventDefault();
    console.log(formValues);
    if (
      formValues.name !== "" &&
      formValues.email !== "" &&
      formValues.contact !== "" &&
      formValues.message !== ""
    ) {
      emailjs
        .sendForm(
          "service_lpmjmyb",
          "template_jz5wm4h",
          form.current,
          "89d7VN0gJPNJ_nun-"
        )
        .then(
          (result) => {
            // console.log(result.text)
            if (result.text === "OK") {
              toast.success("Massage sent successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setFormValues({
                name: "",
                email: "",
                message: "",
                contact: "",
              });
              setErrorStatus({
                name: false,
                email: false,
                message: false,
                contact: false,
              });
              setSendStatus(false);
            }
          },
          (error) => {
            console.log(error.text);
            toast.error("Something went wront try again!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFormValues({
              name: "",
              email: "",
              message: "",
              contact: "",
            });
            setErrorStatus({
              name: false,
              email: false,
              message: false,
              contact: false,
            });
            setSendStatus(false);
          }
        );
    } else {
      let validEmailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      setErrorStatus({
        name: formValues.name === "",
        email: !validEmailRegex.test(formValues.email),
        message: formValues.message === "",
        contact: formValues.contact === "",
      });
      console.log(errorStatus);
    }
  };
  return (
    <div className="relative flex items-top justify-center min-h-screen bg-white sm:items-center sm:pt-0">
      <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
        <div className="mt-8 overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-6 mr-2 bg-gray-100   sm:rounded-lg">
              <h1 className="text-4xl sm:text-5xl text-gray-800   font-extrabold tracking-tight">
                Get in touch
              </h1>
              <p className="text-normal text-lg sm:text-2xl font-medium text-gray-600   mt-2">
                Fill in the form to start a conversation
              </p>
              <div className="flex items-center mt-8 text-gray-600  ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <div className="ml-4 text-md tracking-wide font-semibold w-full">
                  121,FF SARJAN -II COMPLEX , OPP.SAMRUDHHI COMPLEX , NEW CG
                  ROAD , CHANDKHEDA , AHMEDABAD -382424
                </div>
              </div>
              <div className="flex items-center mt-4 text-gray-600  ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <div className="ml-4 text-md tracking-wide font-semibold w-40">
                  <a href="tel:+919558886009">+91 955 888 6009</a>
                </div>
              </div>
              <div className="flex items-center mt-4 text-gray-600  ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <div className="ml-4 text-md tracking-wide font-semibold w-40">
                  <a href="tel:+917859831208">+91 785 983 1208</a>
                </div>
              </div>
              <div className="flex items-center mt-4 text-gray-600  ">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="w-8 h-8 text-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <div className="ml-4 text-md tracking-wide font-semibold w-40">
                  <a href="mailto:info@alphaappliances.in">
                    info@alphaappliences.in
                  </a>
                </div>
              </div>
            </div>
            <form
              className="p-6 flex flex-col justify-center"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="flex flex-col">
                <label htmlFor="name" className="hidden">
                  Full Name
                </label>
                <input
                  type="name"
                  name="from_name"
                  id="name"
                  placeholder="Full Name"
                  className="w-100 mt-2 py-3 px-3 rounded-lg bg-white   border border-gray-400   text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  value={formValues.name}
                  required
                  onChange={(e) => {
                    setFormValues({ ...formValues, name: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col mt-2">
                <label htmlFor="email" className="hidden">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="w-100 mt-2 py-3 px-3 rounded-lg bg-white   border border-gray-400   text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  required
                  value={formValues.email}
                  onChange={(e) => {
                    setFormValues({ ...formValues, email: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col mt-2">
                <label htmlFor="tel" className="hidden">
                  Number
                </label>
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  placeholder="Telephone Number"
                  className="w-100 mt-2 py-3 px-3 rounded-lg bg-white   border border-gray-400   text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  required
                  value={formValues.contact}
                  onChange={(e) => {
                    setFormValues({ ...formValues, contact: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="name" className="hidden">
                  Message
                </label>
                <textarea
                  type="name"
                  name="message"
                  id="message"
                  placeholder="Message"
                  className="w-100 mt-2 py-3 px-3 rounded-lg bg-white   border border-gray-400   text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none"
                  required
                  value={formValues.message}
                  onChange={(e) => {
                    setFormValues({ ...formValues, message: e.target.value });
                  }}
                />
              </div>
              <button
                type="submit"
                className="md:w-32 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300"
                disabled={sendStatus}
              >
                {sendStatus ? "Sending..." : "Submit"}
              </button>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
