import React from "react";
import { Link } from "react-router-dom";

const Features = () => {
  return (
    <section className="pt-20 lg:pt-[120px] pb-10 lg:pb-20 bg-[#F3F4F6] px-8">
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-3 -mx-4">
          <div className="w-full  px-4 my-4 sm:my-0">
            <div className="bg-white rounded-lg overflow-hidden mb-10 h-full">
              <img
                src={"img/home/trendy.webp"}
                alt="image"
                className="w-full"
              />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <span
                    className="
                  font-semibold
                  text-dark text-xl
                  sm:text-[22px]
                  md:text-xl
                  lg:text-[22px]
                  xl:text-xl
                  2xl:text-[22px]
                  mb-4
                  block
                  hover:text-primary
                  "
                  >
                    Find Your Perfect Appliance
                  </span>
                </h3>
                <p className="text-base text-body-color leading-relaxed mb-7 text-justify">
                  We offer electric chimneys, gas stoves, and hobs to suit your
                  cooking needs. Our products are designed to remove smoke and
                  built to perform 360 degree stereo inflow structure design to
                  help effectively capturing and eliminating cooking odours.
                </p>
                <Link
                  to={`/products`}
                  className="
               inline-block
               py-2
               px-7
               border border-[#E5E7EB]
               rounded-full
               text-base text-body-color
               font-medium
               hover:border-primary hover:bg-indigo-500 hover:text-white
               transition
               "
                >
                  View Products
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full  px-4 my-4 sm:my-0">
            <div className="bg-white rounded-lg overflow-hidden mb-10 h-full">
              <img
                src={"img/home/chimney_3.webp"}
                alt="image"
                className="w-full"
              />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <span
                    className="
                  font-semibold
                  text-dark text-xl
                  sm:text-[22px]
                  md:text-xl
                  lg:text-[22px]
                  xl:text-xl
                  2xl:text-[22px]
                  mb-4
                  block
                  hover:text-primary
                  "
                  >
                    Effortless Appliance Installation
                  </span>
                </h3>
                <p className="text-base text-body-color leading-relaxed mb-7 text-justify">
                  Get your new appliances up and running with ease. Our team of
                  expert technicians will handle the installation process,
                  ensuring that your appliances are set up correctly and that
                  you're completely satisfied with your purchase.
                </p>
                <Link
                  to={"/contact-us"}
                  className="
               inline-block
               py-2
               px-7
               border border-[#E5E7EB]
               rounded-full
               text-base text-body-color
               font-medium
               hover:border-primary hover:bg-indigo-500 hover:text-white
               transition
               "
                >
                  Inquiry Now
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full px-4 my-4 sm:my-0">
            <div className="bg-white rounded-lg overflow-hidden mb-10 h-full">
              <img
                src={"img/home/curvee.webp"}
                alt="image"
                className="w-full"
              />
              <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                <h3>
                  <span
                    className="
                  font-semibold
                  text-dark text-xl
                  sm:text-[22px]
                  md:text-xl
                  lg:text-[22px]
                  xl:text-xl
                  2xl:text-[22px]
                  mb-4
                  block
                  hover:text-primary
                  "
                  >
                    We're Here to Help
                  </span>
                </h3>
                <p className="text-base text-body-color leading-relaxed mb-7 text-justify">
                  Need help with your appliances? Our knowledgeable and friendly
                  customer support team is here to assist you every step of the
                  way. Contact us for answers to your questions, troubleshooting
                  help, and more.
                </p>
                <Link
                  to={`/contact-us`}
                  className="
               inline-block
               py-2
               px-7
               border border-[#E5E7EB]
               rounded-full
               text-base text-body-color
               font-medium
               hover:border-primary hover:bg-indigo-500 hover:text-white
               transition
               "
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
