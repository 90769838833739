import React from "react";
import { useTranslation } from "react-i18next";
import CallToActiion from "../components/common/CallToActiion";
import Features from "../components/common/Features";
import Services from "../components/common/Services";
import States from "../components/common/States";
import Testimonials from "../components/common/Testimonials";

const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-gray-100">
      <CallToActiion t={t} i18n={i18n}></CallToActiion>
      {/* <States></States> */}
      <Features></Features>
      <Services></Services>
      <Testimonials></Testimonials>
    </div>
  );
};

export default Home;
